import React from "react";
import { Grid, Skeleton } from "@mui/material";
import JobGridItem from "./JobGridItem";

import { useSettings } from "../util/SettingsContext";

/**
 * Renders a Grid of jobs, based on the JSON object passed in the props
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobGrid
 */
const JobGrid = ({
  jobs,
  subtitleAttribute,
  ReferenceNumberAttribute,
  loading = false,
  favourites = "toggle",
} = props) => {
  // get Settings from Context
  const [settings] = useSettings();

  const jobList = jobs ? jobs : [];
  //console.log(jobList);

  if (!Array.isArray(jobs)) {
    return <div>Invalid jobs list, expected an array of job objects</div>;
  }

  if (loading) {
    return (
      <div>
        <Skeleton width={"100%"} height={200} variant="wave" />
      </div>
    );
  }

  return (
    <Grid className="job-grid" container spacing={2}>
      {jobList.length > 0 &&
        jobList.map((job) => (
          <Grid
            key={job.id}
            item
            xs={12}
            sm={12}
            md={12 / (settings.jobGridColumns - 1)}
            lg={12 / settings.jobGridColumns}
          >
            <JobGridItem
              key={job.id}
              job={job}
              subtitleAttribute={subtitleAttribute}
              ReferenceNumberAttribute={ReferenceNumberAttribute}
              favourites={favourites}
            />
          </Grid>
        ))}
      {!jobList && <div>No jobs found</div>}
    </Grid>
  );
};

export default JobGrid;
