import React, { createContext, useContext, useEffect, useState } from "react";

//
import { useLoadFilters } from "./JobQueryService";

// used hooks
import { useLocation } from "react-router-dom";
import { useSettings } from "./SettingsContext";

// Context for accessing Job Filters ("Attributes") of the current projects from anywhere in the app (see React Context)
// Job attributes are typically loaded once from the backend and then cached in the context.
const JobFiltersContext = createContext(undefined);

// Hook to provide access to context object.
const useJobFilters = () => {
  return useContext(JobFiltersContext);
};

/**
 * Returns data from the backend API for the job filters ("attributes ")
 */
const JobFiltersContextProvider = (props) => {
  const [settings] = useSettings();
  const lang = new URLSearchParams(useLocation().search).get("lang") || "de";

  // This will use the custom hook to load the filters from the backend
  const { isLoading, isError, data } = useLoadFilters(settings.baseUrl, lang);

  // Local storage state for the job favourites
  const [filters, setFilters] = useState({ isLoading, isError, data });

  // Load available filter options (usually only once)
  useEffect(() => {
    // Load the filters from the backend
    setFilters({ isLoading, isError, data });
  }, [data]);

  return (
    <JobFiltersContext.Provider value={filters}>
      {props.children}
    </JobFiltersContext.Provider>
  );
};

export { JobFiltersContextProvider, useJobFilters };
