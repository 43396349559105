import React, { useEffect, useState } from "react";
import { i18n } from "../util/i18n";
import { Button, TextField, Box, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useDebounce } from "ahooks";
import { useSettings } from "../util/SettingsContext";

/**
 * A React component that controls the text search filter.
 * It supports debouncing, so the filterParams (passed down by the parent) are only updated after a period of time.
 * The setting to control this is settings.textSearchFilterDebounceTime
 */

const TextSearchFilterInput = ({ filterParams, setFilterParams }) => {
  const [settings, setSettings] = useSettings();

  // We use a local state to store the current filter state.
  // The debouncing will update te setFilterParams only after a period of time
  const [internalFilterState, setInternalFilterState] = useState(filterParams);

  // we want to debounce the text search input, so we use the "useDebounce" hook from "ahooks"
  // see: https://ahooks.js.org/hooks/use-debounce
  const debouncedFilterState = useDebounce(internalFilterState, {
    wait: settings.textSearchFilterDebounceTime || 500,
  });

  /**
   * Handle the change of the text search input field.
   * We create a new filterParams object and update the local state.
   * At this point, the (global) filterParams are not updated yet.
   */
  const handleTextSearchChange = (event) => {
    const newFilterParams = {
      ...filterParams, // keep the existing filter params
      q: event.target.value, // set "q" param to the new value
      offset: 0, // always clear offset when changing filters
    };
    // update our internal state
    setInternalFilterState(newFilterParams);
  };

  // ONLY after the debounced filter state has changed, we update the (global) filter params
  useEffect(() => {
    setFilterParams(debouncedFilterState);
  }, [debouncedFilterState]);

  // if the filterParams change, we update our internal state (e.g. when the user clicks "reset")
  useEffect(() => {
    setInternalFilterState(filterParams);
  }, [filterParams]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InputBase
          fullWidth
          onChange={handleTextSearchChange}
          placeholder={i18n.translations.searchPlaceholder}
          variant="standard"
          value={internalFilterState?.q ? internalFilterState.q : ""}
        />
        <IconButton type="button" sx={{ p: "12px 28px 11px 18px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default TextSearchFilterInput;
