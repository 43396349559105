/**
 * Main App component.
 * This is the main component of the app, it contains the main layout and the main logic.
 */
import React, { useState, useEffect } from "react";

// import our utility functions for working with filters and languages
import { i18n } from "./util/i18n";

// Hooks to get state from Context objects
// Global settings for this project, from settings.js
import { useSettings } from "./util/SettingsContext";

// Job attributes context for the job attributes (e.g. Berufsgruppe, Institut)
import { useJobFilters } from "./util/JobFiltersContext";

// Service to fetch (filtered) jobs from the backend.
import { useLoadJobs } from "./util/JobQueryService";

// Job filter Parameters. These are modified by the filter components and used to fetch the jobs from the backend API
import { createFetchUrl, useJobFilterParams } from "./util/JobFilterParams";

// Our reusable components
import FilterSection from "./components/FilterSection";
import JobList from "./components/JobList";
import NoJobsFound from "./components/NoJobsFound";
import SideMenu from "./components/SideMenu";
import JobListPagination from "./components/JobListPagination";

// MUI components and other imports
import {
  Grid,
  LinearProgress,
  Skeleton,
  Slider, Stack,
  Switch,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import FavouriteCounterButton from "./components/FavouriteCounterButton";

import JobGrid from "./components/JobGrid";
import JobAboSubscriptionModal from "./components/JobAboSubscriptionModal";
import {Route, Routes, Outlet} from "react-router-dom";
import JobAboSubscriptionForm from "./components/JobAboSubscriptionForm";

/**
 *
 *
 */
function App() {
  // Get our (global) settings from the context
  const [settings, setSettings] = useSettings();

  // 2. define constants for filter ids here
  const FILTER = settings.filterIds;

  // Load available filter data (e.g. the "attributes" that are available for this project)
  // This remains stable after loaded once, that's why we put it in a global context
  const {
    isLoading: isLoadingFilters,
    isError: isErrorFilters,
    data: filterData,
  } = useJobFilters();

  // State for the currently set filter parameters.
  // These are the filter parameters that we dynamically set when the user changes the filters.
  // These are used to fetch the jobs from the backend API, and modified by the filter components.
  const [filterParams, setFilterParams] = useJobFilterParams();

  // This uses useQuery hook, e.g. to fetch the jobs from the backend API.
  // NOTE: use this uses a useQuery hook, we can't use it inside a loop or condition or useEffect, so this gets called
  // a lot of times, but it will only fetch the data once, and then cache it.

  // Note that if settings.autoSubmit == false and the "SubmitRequested" flag is not set, we don't fetch the jobs.

  const url = createFetchUrl(filterParams);
  const { isLoading, isError, data } = useLoadJobs(url);

  const CC = <Container maxWidth="lg">
    {isLoadingFilters && <LinearProgress />}
    {isError && <p>Error loading Jobs: {isError} ...</p>}
    {filterData && (
        <FilterSection
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            attributes={filterData.attributes}
        />
    )}
    {settings.enableFavourites && (
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item xs={4} sm={2} className="job-favourite-button">
            <FavouriteCounterButton loading={!data} />
          </Grid>
        </Grid>
    )}

    <div className="jobsList">

      <Grid container spacing={{xs:0, md:8}}>
        <Grid item xs={12} md={8}>
          {isLoading && <LinearProgress />}
          {data && data.total === 0 && (
              <>
                <NoJobsFound />
              </>
          )}
          {data &&
              data.total > 0 &&
              (settings.jobGrid ? (
                  <JobGrid
                      jobs={data.jobs}
                      locationAttribute={FILTER["Arbeitsort"]}
                      ReferenceNumberAttribute={FILTER["ReferenceNumber"]}
                      subtitleAttribute={FILTER["ShortDescription"]}
                      favourites={settings.enableFavourites ? "toggle" : "none"}
                  />
              ) : (
                  <JobList
                      jobs={data.jobs}
                      locationAttribute={FILTER["Arbeitsort"]}
                      ReferenceNumberAttribute={FILTER["ReferenceNumber"]}
                      subtitleAttribute={FILTER["ShortDescription"]}
                      favourites={settings.enableFavourites ? "toggle" : "none"}
                  />
              ))}
        </Grid>
        <Grid item xs={12} md={4} order={{ xs: 3, md: 2 }}>
          {filterData && (
              <>
                <SideMenu
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    attributes={filterData.attributes}
                />
              </>
          )}
        </Grid>
              {data && data.total > 0 && (
        <Grid item xs={12} order={{ xs: 2, md: 3 }} style={{padding:0}}>
       <JobListPagination
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              totalJobs={data.total}
          />
        </Grid>
     
      )}
      </Grid>




      {!data && (
          <>
            <Skeleton
                sx={{ bgcolor: "white" }}
                variant="rectangular"
                width={"100%"}
                height={"500px"}
            />
          </>
      )}
    </div>
  </Container>;

  const jobAboSubscriptionForm = (<>
    {filterData &&
   <JobAboSubscriptionForm
        attributes={filterData.attributes}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        className="jobabo-subscription-form"
    />}
  </>);

  return (
      <Routes>
        <Route path="/" element={<><Outlet/></>}>
          <Route index element={CC} />
          <Route path="jobabo" element={jobAboSubscriptionForm} />
          <Route path="*" element={<>No match</>} />
        </Route>
      </Routes>

  );
}

export default App;
