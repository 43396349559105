import { useEffect, useState } from "react";

import { useSettings } from "./SettingsContext";


/**
 * Converts an Url query String (?lang=de&q=...&f=70:1216240&f=80:1218652&workload=10,80...) to a filterParams object
 * @param {string} queryString
 * @param {object} settings - settings object, for defaults (e.g. jobsPageSize)
 * @returns filter params object
 */
export const queryStringToFilterParams = (queryString) => {
  const queryParams = new URLSearchParams(queryString); //getQueryStringParams(queryString);
  const [settings, setSettings] = useSettings();

  const filterParams = {
    lang: queryParams.get("lang") || "de",
    q: queryParams.get("q") || null,
    offset: parseInt(queryParams.get("offset")) || 0,
    limit: parseInt(queryParams.get("limit")) || settings.jobsPageSize || 5,
    workload:
      queryParams
        .get("workload")
        ?.split(",")
        .map((d) => parseInt(d)) || null,
    // filter params is empty, parsed in next step
    f: {},
  };

  // as "f" param is an array, we need to parse it manually
  queryParams.getAll("f").map((param) => {
    const [filterId, filterValues] = param.split(":");
    filterParams.f[filterId] = filterValues.split(",");
  });

  return filterParams;
};

/**
 *
 * @param {filter Params object} filterParams
 * @returns query string
 */
export const filterParamsToQueryString = (filterParams) => {
  //console.log("filterParamsToQueryString", filterParams);
  const [settings] = useSettings();
  let queryParams = "";
  queryParams += `lang=${filterParams.lang}`;
  queryParams += filterParams.q ? `&q=${filterParams.q}` : "";
  queryParams += `&offset=${filterParams.offset || 0}`;
  queryParams += `&limit=${settings.jobsPageSize || 4}`;
  queryParams += filterParams.workload
    ? `&workload=${filterParams.workload}`
    : "";

  // as "f" param is an array, we need to parse it manually
  Object.keys(filterParams.f || {}).map((filterId) => {
    const filterValues = filterParams.f[filterId];
    queryParams += `&f=${filterId}:${filterValues}`;
  });

  //console.log("queryParams", queryParams);

  return queryParams.toString();
};

/**
 * Hook to provide current filter parameters, initialized from current location url
 * @returns
 */
export function useUrlParams() {


  // extract filter Params from current location url
  const filterParams = queryStringToFilterParams(
    window.location.search
  );

  //console.log("filterParams", filterParams);
  const [urlParams] = useState(filterParams);

  return urlParams;
}
