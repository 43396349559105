import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { i18n } from "../util/i18n";

const NoJobsFound = () => {
  return (
    <>
      <Typography variant="strong">{i18n.translations.noJobs}</Typography>
    </>
  );
};

export default NoJobsFound;
