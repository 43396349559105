import React from "react";
import {Divider, Grid, List, ListItem, ListItemText, Skeleton} from "@mui/material";
import Favourite from "./Favourite";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const getPensum = (job) => {
  let min = job?.szas["sza_pensum.min"] || "";
  let max = job?.szas["sza_pensum.max"] || "";

  if (min == max) {
    return `${min} %`;
  } else {
    return `${min} ${min ? "-" : ""} ${max} %`;
  }
};

/**
 * Renders a single Job item, typically as part of a JobList.
 * This requires a "job" JSON object as a prop, as provided by the backend API.
 *
 *
 *
 * @param {job JSON object, as coming from the API} job
 * @param {subtitleAttribute} subtitleAttribute - the attribute to use as the subtitle (e.g. 80, to use job.attributes[80])
 * @param {favourites} favourites - "toggle" to show a favourite toggle button, "remove" to show the button as remove, "none" to hide the button
 * @returns JSX for the Job
 */
const JobListItem = ({
  job,
  locationAttribute,
  ReferenceNumberAttribute,
  subtitleAttribute,
  favourites = "toggle",
} = props) => {
  if (!job) {
    return <div>Invalid job, expected a job object</div>;
  }
  return (
      <>
      <a className="job" href={job.links.directlink} target="_blank">
        <ListItem className="job-list-item" key={job.id}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container className="meta" columnSpacing={2} justifyContent="space-between">
                  <Grid item xs={12} sm={4}>
                      {getPensum(job)!==" %" && (
                          <span className="data">{"Pensum: " + getPensum(job)}</span>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                      {job.attributes[locationAttribute] && (
                          <span className="data">{"Arbeitsort: " + job.attributes[locationAttribute]?.join(", ")}</span>
                      )}
                  </Grid>
                    <Grid item xs={12} sm={4}>
                        {job.attributes[ReferenceNumberAttribute] && (
                            <span className="data">{"Job-ID: " + job.attributes[ReferenceNumberAttribute]?.join(", ")}</span>
                        )}
                    </Grid>
                </Grid>
                <ListItemText primary={job.title} />
                <p className="MuiListItemText-secondary" dangerouslySetInnerHTML={{__html: (subtitleAttribute ? job.attributes[subtitleAttribute]?.join(", ") : null)}}/>
            </Grid>
          </Grid>
        </ListItem>
      </a>
        <Divider />
      </>
  );
};

export default JobListItem;
