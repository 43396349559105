import React, { createContext, useContext, useState } from "react";
import { default as loadedSettings } from "../settings";

// Context for accessing Settings from anywhere in the app (see React Context)
// We will include this in the index.js file, so that it is available to all components
// see https://contactmentor.com/react-context-with-hooks/
const SettingsContext = createContext(undefined);

// Hook to provide access to context object.
// You can get the settings object from the nearest context provider (typically global),
// like this: const [settings, setSettings] = useSettings()
const useSettings = () => {
  return useContext(SettingsContext);
};

/**
 * Settings context provider, uses a React useState hook to provide access to the settings object
 */
const SettingsContextProvider = (props) => {
  const [settings, setSettings] = useState(loadedSettings);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export { SettingsContextProvider, useSettings };
