/**
 * @file index.js
 *
 * This is the entry point for the React app.
 *
 * We'll setup things like the react-query client, and the global context providers here, theming etc.
 * Typically, you won't need to edit this file.
 * For more information on React Context, see https://reactjs.org/docs/context.html
 *
 */

import React, { useState, createContext } from "react";
import { createRoot } from "react-dom/client";

// React Router, used for routing with the page URL, history etc.
import {BrowserRouter, HashRouter, Route} from "react-router-dom";

// Changing CSS injection order to be able to use our stylesheet without !important
import { StyledEngineProvider } from "@mui/material/styles";

// Global Context providers give us access to global state
// See https://react-query-v3.tanstack.com/overview
import { QueryClientProvider, QueryClient } from "react-query";

// App Settings Context, provides global access to settings.js
import { SettingsContextProvider } from "./util/SettingsContext";

// Local Storage Context, provides access to local storage (for Favourites etc.)
import { LocalStorageContextProvider } from "./util/LocalStorageContext";

// Provides project-specific job filters ("attributes")
import { JobFiltersContextProvider } from "./util/JobFiltersContext";

// react-query client, this is used for caching and managing API calls
const queryClient = new QueryClient();

import App from "./App";
import "./index.css";
import {Switch} from "@mui/material";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HashRouter>
    <QueryClientProvider client={queryClient}>
      <SettingsContextProvider>
        <LocalStorageContextProvider>
          <JobFiltersContextProvider>
            <StyledEngineProvider injectFirst>
<App />
            </StyledEngineProvider>
          </JobFiltersContextProvider>
        </LocalStorageContextProvider>
      </SettingsContextProvider>
    </QueryClientProvider>
  </HashRouter>
);
