import { Button, Checkbox } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { i18n } from "../util/i18n";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Local storage context for favourites etc.
import { useLocalStorage } from "../util/LocalStorageContext";

/**
 * A Favourite Button component, e.g. for adding a Job to the Favourites
 * https://github.com/streamich/react-use/blob/master/docs/useLocalStorage.md
 * https://mui.com/material-ui/react-checkbox/#icon
 *
 */

const Favourite = ({ job, loading, bookmark = false, ...props }) => {
  // Use our (global) local storage context
  const jobId = job.id;
  const [stored, setStored] = useLocalStorage();
  //console.log("stored", stored);

  const icon =
    props.icon || bookmark ? <BookmarkBorderIcon /> : <FavoriteBorderIcon />;
  const checkedIcon =
    props.checkIcon || bookmark ? <BookmarkIcon /> : <FavoriteIcon />;

  const handleFavouriteToggle = (event) => {
    let newState = { ...stored }; // copy the current state
    // toggle: if the jobId is already existing, remove it. Otherwise, add it.
    if (stored[jobId]) {
      delete newState[jobId];
    } else {
      newState[jobId] = job;
    }

    setStored(newState);
  };

  return (
    <>
      {jobId && (
        <Checkbox
          value={true}
          icon={icon}
          checkedIcon={checkedIcon}
          checked={stored[jobId] ? true : false}
          onChange={handleFavouriteToggle}
          {...props}
        />
      )}
      {!jobId && <div>No JobId</div>}
    </>
  );
};

export default Favourite;
