import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { i18n } from "../util/i18n";
import { Skeleton } from "@mui/material";

import PropTypes from "prop-types";
import { PaginationProps } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * This is a reusable pagination component for Job Lists.
 * It uses the filterParams object to get the current offset and limit (= page size) and also the total number of jobs for the query.
 * It displays a Pagination control from Material UI.
 * If you pass in a setFilterParams function, it will be called when the user clicks on a page number with the updated offset property
 * (so that you can then fetch the new page of jobs).
 *
 * @param {} filterParams The current filterParams object. Important properties: offset, limit
 * @param {*} setFilterParams A useState setter function for the filterParams object
 * @param {*} totalJobs Total number of jobs for the query. This is used to calculate the number of pages.
 * @param {*} loading if True the component will display a loading indicator instead of the pagination control
 * @param {*} props Will be spread onto the Pagination component. You can use this e.g. for custom styling via sx={{...}} property.
 *
 * @returns JobListPagination component
 */
const JobListPagination = ({
  className,
  filterParams,
  setFilterParams,
  totalJobs,
  loading = false,
  ...props
}) => {
  const currentPage = filterParams?.offset / filterParams?.limit + 1;
  const totalPages = Math.ceil(totalJobs / filterParams?.limit);

  let empty = totalJobs === 0;

  let error =
    !filterParams ||
    !setFilterParams ||
    filterParams.offset < 0 ||
    filterParams.limit <= 0 ||
    filterParams.offset > totalJobs;

  const handleOnChange = (event, page) => {
    //console.log("Going to page:", actualPage);
    setFilterParams({
      ...filterParams,
      offset: (page - 1) * filterParams.limit,
      submitted: true,
    });
    document.getElementById('root').scrollIntoView();
  };

  const isMobile= useMediaQuery('(max-width:600px)');

  return (
    <>
      {error && (
        <div>
          ERROR: Invalid or missing required properties for JobListPagination
        </div>
      )}
      {loading && !error && (
        <Skeleton animation="wave" sx={{ bgcolor: "gray.300" }}>
          <Pagination />
        </Skeleton>
      )}
      {!loading && empty && <Pagination {...props} hidden={true} />}

      {!loading && !error && (
        <Pagination
          page={currentPage}
          count={totalPages}
          disabled={totalPages == 1}
          onChange={handleOnChange}
          sx={{mt:0}}
          hidden={totalPages == 0}
          siblingCount={isMobile ? 0 : 1} 
          hideNextButton={!isMobile}
          hidePrevButton={!isMobile}
          {...props}
     
          
        />
      )}
    </>
  );
};

/**
 * Proptypes help us (and our IDEs, and Storybook) to understand the properties of this component.
 * While optional, a god idea in general to have.
 * See docs: https://reactjs.org/docs/typechecking-with-proptypes.html
 * See: https://github.com/facebook/prop-types
 */
JobListPagination.propTypes = {
  filterParams: PropTypes.object.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  totalJobs: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  //  ...PaginationProps
};

export default JobListPagination;
