/***
 *
 * This library is used to query the Career Center JobLists from our API server.
 * API docs: ...
 *
 * Uses the following libraries:
 * - react-query (useQuery hook): https://tanstack.com/query/v4/docs/react/overview
 *
 */

import { useQuery } from "react-query";

// import our utility functions for working with filters and languages
import { i18n } from "./i18n";

const attributesToFilters = (attributes) => {
  return attributes;
};

/**
 * Loads the filters (attributes) from the backend API.
 *
 * @param {*} baseUrl
 * @param {*} language
 * @returns
 */
const useLoadFilters = (baseUrl, language = "de") => {
  return useQuery(
    ["attributes"],
    () => {
      return fetch(`${baseUrl}/attributes?lang=${language}&returnValuesAsArray=1`)
        .then((res) => {
          if (!res.ok) {
            console.log("Error fetching Filter attributes from ", res.url);
            return Promise.reject(res);
          }
          return res.json();
        })
        .then((data) => {
          let filters = attributesToFilters(data);
          console.log("Loaded project filters  (attributes)", filters);
          return filters;
        });
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

/**
 * Loads the job list from the backend API.
 * @param {url for loading the job list} jobListFetchUrl.
 * You may want to use JobFilterParams.createFetchUrl() to create this URL.
 *
 * See https://tanstack.com/query/v4/docs/react/guides/queries
 *
 * @returns
 */
const useLoadJobs = (jobListFetchUrl) => {
  return useQuery(
    ["joblist", jobListFetchUrl],
    () => {
      return fetch(jobListFetchUrl)
        .then((res) => {
          if (!res.ok) {
            console.log("Error fetching jobs from ", jobListFetchUrl);
            return Promise.reject(res);
          }

          return res.json();
        })
        .then((data) => {
          console.log("---");
          console.log("jobListFetchUrl: ", jobListFetchUrl);
          console.log("received job list from server: ", data);
          console.log("total jobs: ", data.total);
          console.log("---");

          return data;
        });
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );
};

export { useLoadFilters, useLoadJobs };
