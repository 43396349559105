//TODO FIXME implement from HOLCIM (inViewPort styling)

import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItemText,
  Typography,
} from "@mui/material";
import Favourite from "./Favourite";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/**
 * Renders a single Job item, typically as part of a JobGrid.
 * This requires a "job" JSON object as a prop, as provided by the backend API.
 *
 *
 *
 * @param {job JSON object, as coming from the API} job
 * @param {subtitleAttribute} subtitleAttribute - the attribute to use as the subtitle (e.g. 80, to use job.attributes[80])
 * @param {favourites} favourites - "toggle" to show a favourite toggle button, "remove" to show the button as remove, "none" to hide the button
 * @returns JSX for the Job
 */
const JobGridItem = ({
  job,
  subtitleAttribute,
  ReferenceNumberAttribute,
  favourites = "toggle",
} = props) => {
  if (!job) {
    return <div>Invalid job, expected a job object</div>;
  }
  return (
    <Card
      sx={{ minWidth: 100, height: "100%" }}
      className="job-grid-item"
      key={job.id}
    >
      <CardContent sx={{ height: "75%" }}>
        <Typography variant="h5" component="div">
          <a className="job" href={job.links.directlink} target="_blank">
            <ListItemText
              primary={job.title}
              secondary={
                subtitleAttribute
                  ? job.attributes[subtitleAttribute]?.join(", ")
                  : null
              }
            />
          </a>
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <a className="job" href={job.links.directlink} target="_blank">
              <ArrowForwardIcon />
            </a>
          </Grid>
          <Grid item xs={6}>
            {favourites == "toggle" && <Favourite job={job} />}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default JobGridItem;
