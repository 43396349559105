import {
  Button,
  Checkbox,
  Drawer,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { i18n } from "../util/i18n";

// Local storage context for favourites etc.
import { useLocalStorage } from "../util/LocalStorageContext";
import JobList from "./JobList";
import Settings from "../settings";

/**
 * Collapsible Drawer component that shows a list of "Favourite Jobs" from local storage,
 * and buttons to:
 * - Create an email with a list of favourite jobs
 * - Clear all favourite jobs (remove from local storage)
 *
 * @param {*} param0
 * @returns
 */
const FavouriteDrawer = ({
  loading,
  bookmark = false,
  open = false,
  onClose,
  ...props
}) => {
  // Use our (global) local storage context
  const [stored, setStored] = useLocalStorage();

  // Number of fav jobs in the local storage
  const numFavourites = Object.keys(stored || {}).length;

  const jobList = Object.values(stored || {});

  const FILTER = Settings.filterIds;

  const handleDeleteAll = (event) => {
    console.log("deleting all favourite jobs", event);
    setStored({});
  };

  const constructEmail = () => {
    const favJobsEmailTitle =
      i18n.translations.favJobsEmailTitle || "Favourite Jobs";
    const preBody =
      i18n.translations.favJobsEmailPrebody ||
      "[Please set favJobsEmailPrebody in translations.js]";
    const postBody =
      i18n.translations.favJobsEmailPostbody ||
      "[Please set favJobsEmailPostbody in translations.js]";
    const body = jobList
      .map((job) => job.title + "\n" + job.links.directlink)
      .join("\n\n");

    const fullbody = encodeURIComponent(`${preBody}\n\n${body}\n\n${postBody}`);

    return `mailto:?subject=${favJobsEmailTitle}&body=${fullbody}`;
  };

  return (
    <>
      <Drawer anchor="top" open={open} onClose={onClose}>
        <Stack direction={"column"} spacing={2}>
          <div className="drawer-header">
            <Typography variant="h5">
              {numFavourites} {i18n.translations.favJobsDrawerHeader}
            </Typography>
          </div>
          <div className="drawer-body">
            <JobList
              jobs={jobList}
              subtitleAttribute={FILTER["Berufsgruppe"]}
              favourites="remove"
            />
          </div>
          <div className="drawer-footer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Link href={constructEmail()} underline="none">
                  {i18n.translations.favJobsSendByEmail}
                </Link>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  onClick={handleDeleteAll}
                  sx={{ width: 265 }}
                >
                  {i18n.translations.favJobsRemoveAll}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Drawer>
    </>
  );
};

export default FavouriteDrawer;
