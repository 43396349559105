import React from "react";
import {Divider, Grid, List, ListItem, ListItemText, Skeleton} from "@mui/material";
import Favourite from "./Favourite";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import JobListItem from "./JobListItem";

/**
 * Renders a list of jobs, based on the JSON object passed in the props
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobList
 */
const JobList = ({
  jobs,
  locationAttribute,
  ReferenceNumberAttribute,
  subtitleAttribute,
  loading = false,
  favourites = "toggle",
} = props) => {
  const jobList = jobs ? jobs : [];
  //console.log(jobList);

  if (!Array.isArray(jobs)) {
    return <div>Invalid jobs list, expected an array of job objects</div>;
  }

  if (loading) {
    return (
      <div>
        <Skeleton width={"100%"} height={200} variant="wave" />
      </div>
    );
  }

  return (
      <>
        <Divider />
        <List className="job-list">
          {jobList.length > 0 &&
              jobList.map((job) => (
                  <JobListItem
                      key={job.id}
                      job={job}
                      locationAttribute={locationAttribute}
                      ReferenceNumberAttribute={ReferenceNumberAttribute}
                      subtitleAttribute={subtitleAttribute}
                      favourites={favourites}
                  />
              ))}
          {!jobList && <div>No jobs found</div>}
        </List>
      </>
  );
};

export default JobList;
