import React, { createContext, useContext, useState } from "react";

// Specific hooks
import { useLocalStorage as useLocalStorageHook } from "react-use";
import { useSettings } from "./SettingsContext";

// Context for accessing local storage from anywhere in the app (see React Context)
// see https://contactmentor.com/react-context-with-hooks/
const LocalStorageContext = createContext(undefined);

// Hook to provide access to context object.
// You can get the settings object from the nearest context provider (typically global),
// like this: const [localStorage, setLocalStorage] = useLocalStorage()
const useLocalStorage = () => {
  return useContext(LocalStorageContext);
};

/**
 * Local Storage context provider, uses react-use/useLocalStorage hook to provide access to local storage under the
 * configired key (see settings.js)
 */
const LocalStorageContextProvider = (props) => {
  const [settings] = useSettings();

  // Local storage state for the job favourites
  const [localStorage, setLocalStorage] = useLocalStorageHook(
    settings.localStorageKeyFavourites,
    {}
  );

  return (
    <LocalStorageContext.Provider value={[localStorage, setLocalStorage]}>
      {props.children}
    </LocalStorageContext.Provider>
  );
};

export { LocalStorageContextProvider, useLocalStorage };
