import { Button } from "@mui/material";
import React from "react";
import { i18n } from "../util/i18n";

/**
 * A React Submit Button component, e.g. for submitting the form to subscribe to a JobAbo
 */

const JobAboSubmitButton = ({
  filterParams,
  setFilterParams,
  onSubmit: onClick = null,
  ...props
}) => {
  const handleClick = (e) => {
    console.info("SubmitButton clicked");
  };

  return (
    <>
      <Button
        className="jobabo-submit-button"
        variant="contained"
        onClick={onClick ? onClick : handleClick}
        type="submit"
        sx={{ marginLeft: 10 }}
      >
        {i18n.translations.jobaboModalButtonLabel}
      </Button>
    </>
  );
};

export default JobAboSubmitButton;
