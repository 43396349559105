import React from "react";
import {
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import JobAboSubscriptionForm from "./JobAboSubscriptionForm";

import { i18n } from "../util/i18n";

/**
 * Renders a a JobAbo Subscriptionm Form
 * @returns JSX
 */
const JobAboSubscriptionModal = ({
  filterParams,
  setFilterParams,
  attributes = null,
  loading = false,
  ...props
} = props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) {
    return (
      <div>
        <Skeleton width={"100%"} height={200} variant="wave" />
      </div>
    );
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        {i18n.translations.jobaboButtonLabel}
      </Button>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="jobabo">
          <JobAboSubscriptionForm
            attributes={attributes}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            className="jobabo-subscription-form"
          />
        </Box>
      </Dialog>
    </>
  );
};

export default JobAboSubscriptionModal;
