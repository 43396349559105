import { Button } from "@mui/material";
import React from "react";
import { i18n } from "../util/i18n";

/**
 * A React component that resets alls filters to their default values
 */

const ResetFiltersButton = ({
  filterParams,
  setFilterParams,
  setTriggerSubmit,
}) => {
  const handleReset = () => {
    // Reset all filters, keep language and page size, reset offset.
    // All "attribute" filters and workload are removed
    setFilterParams({
      lang: filterParams.lang,
      limit: filterParams.limit,
      offset: 0,
    });
    setTriggerSubmit(true); // reset button needs to always trigger a reload directly
  };

  return (
    <>
      <Button
        className="reset-filters-button"
        variant="outlined"
        onClick={handleReset}
      >
        {i18n.translations.resetFilters}
      </Button>
    </>
  );
};

export default ResetFiltersButton;
