import React, { createContext, useContext, useEffect, useState } from "react";
import { useSettings } from "./SettingsContext";

// used hooks

import { useUrlParams } from "./UrlParams";
import { filterParamsToQueryString } from "./UrlParams";

/**
 * Hook to manage the filter parameters for the job list. Initalized by useUrlParams (e.g. from window.location.search)
 * @returns [jobFilterParams, setJobFilterParams]
 */
const useJobFilterParams = () => {
  const defaultFilterParamsFromUrl = useUrlParams();

  const [jobFilterParams, setJobFilterParams] = useState(
    defaultFilterParamsFromUrl
  );

  return [jobFilterParams, setJobFilterParams];
};

/**
 * Helper method created a (fully qualified) URL for fetching jobs from the backend API.
 * Uses the current filter parameters and the base URL from the settings.
 * @param {filterParams} filterParams
 * @returns
 */
const createFetchUrl = (filterParams) => {
  const [settings] = useSettings();
  return `${settings.baseUrl}/jobs?` + filterParamsToQueryString(filterParams);
};

export { useJobFilterParams, createFetchUrl };
