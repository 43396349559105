import { Button } from "@mui/material";
import React from "react";
import { i18n } from "../util/i18n";

/**
 * A React component that submit the current filters (only needed if nit "autoSubmit")
 */

const SubmitFiltersButton = ({
  filterParams,
  setFilterParams,
  setTriggerSubmit,
}) => {
  const handleSubmit = () => {
    // Keep current filter params, but reset offset to 0
    setTriggerSubmit(true);
    setFilterParams({
      ...filterParams,
      //      submitted: true,
      offset: 0,
    });
  };

  return (
    <>
      <Button
        className="submit-filters-button"
        variant="outlined"
        onClick={handleSubmit}
      >
        {i18n.translations.submitFilters}
      </Button>
    </>
  );
};

export default SubmitFiltersButton;
